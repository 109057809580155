<template>
  <div
      class="pt-12 pb-12 min-h-full background-container">
    <div
        class="p-8 w-11/12 h-11/12 sm:w-8/12 sm:h-8/12 pt-10 pb-10 m-auto bg-white overflow-auto shadow-2xl">
      <p class="text-3xl">Impressum</p>
      <div class="mt-10">
        <p>Angaben gemäß § 5 TMG</p>
        <p>worldiety GmbH</p>
        <p>Marie-Curie-Str. 1</p>
        <p>26129 Oldenburg</p>
      </div>
      <div class="mt-10">
        <p>Handelsregister: 208428</p>
        <p>Registergericht: Amtsgericht Oldenburg (Oldenburg)</p>
      </div>

      <div class="mt-10">
        <p>Vertreten durch:</p>
        <p>Adrian Macha, Torben Schinke</p>
        <p>Telefon: +49 441 559 770 0</p>
        <p>E-Mail: info@worldiety.de</p>
      </div>
      <div class="mt-10">
        <p>Umsatzsteuer-ID</p>
        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</p>
        <p>DE 292/586/281</p>
      </div>
      <div class="mt-24">
        <p class="text-xl">
          Verbundprojekt
        </p>
        <p class="pt-2">Upweerten wurde im Rahmen des Forschungsprojekts Wärmewende Nordwest umgesetzt</p>
        <div class="pl-4 pt-2">
          <img src="@/assets/img/wwnw_logo.webp"
               alt="Wärmewende Nordwest"
               class="lg:w-1/4 sm:1/3">
          <p class="mt-2">
            <a class="text-green-primary hover:text-green-primaryHover"
               target="_blank"
               rel="noopener noreferrer"
               href="https://www.waermewende-nordwest.de/">
              Wärmewende Nordwest
            </a>
          </p>
        </div>
      </div>
      <div class="mt-24">
        <p class="text-xl">
          Fördergeber
        </p>
        <p class="pt-2">
          Gefördert durch das BMBF
        </p>
        <img src="@/assets/img/bmbf_logo.webp"
             alt="BMBF"
             class="lg:w-1/4 sm:1/3">
      </div>
      <div class="mt-10">
        <p class="text-xl">
          Verwendete Grafiken/Lizenzen
        </p>
        <div class="pl-4 pt-2">
          <p>
            Hintergrundbild:
            <a class="text-green-primary hover:text-green-primaryHover"
               href="https://www.flickr.com/photos/southgeist/2311360805/in/photostream/"
               target="_blank"
               rel="noopener noreferrer">
              wenn das mann nicht norddeutsch ist
            </a>
          </p>
          <p>
            <a class="text-green-primary hover:text-green-primaryHover"
               href="https://www.flickr.com/photos/southgeist/"
               target="_blank"
               rel="noopener noreferrer">
              Dirk Ingo Franke
            </a>
          </p>
          <p>
            Lizenz:
            <a class="text-green-primary hover:text-green-primaryHover"
               href="https://creativecommons.org/licenses/by-sa/2.0/"
               target="_blank"
               rel="noopener noreferrer">
              Creative Commons — Attribution-ShareAlike 2.0 Generic — CC BY-SA 2.0
            </a>
          </p>
          <p>
            Original:
            <a class="text-green-primary hover:text-green-primaryHover"
               href="https://www.flickr.com/photos/southgeist/2311360805/in/photostream/"
               target="_blank"
               rel="noopener noreferrer">
              wenn das mann nicht norddeutsch ist
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
